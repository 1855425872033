<template>
  <b-dropdown
  variant="link"
  size="sm"
  no-caret
  toggle-class="text-decoration-none"
  right
  :disabled="isreLoading"
  v-if="user && user.channels.length > 1"
>
  <template #button-content>
    <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="items-web-color">
      <path fill="currentColor" d="M21,9L17,5V8H10V10H17V13M7,11L3,15L7,19V16H14V14H7V11Z" />
    </svg>
  </template>
  <b-dropdown-text class="items-web-color">Secciones canal venta disponibles</b-dropdown-text>

  <b-dropdown-divider/>

  <b-dropdown-item
    v-for="(canal, index) in user.channels"
    :key="index"
    :disabled="activeIf(canal)"
    @click="handlerChannelUser(canal)"
    class="break-word"
  >
    {{canal.seccioncanalventa.name}}
    <!-- <b-spinner small v-if="isLoading"/> -->
  </b-dropdown-item>
</b-dropdown>
</template>

<script>
import {  mapMutations, mapState, mapActions } from 'vuex'
import { showAlertMessage, toJson } from '@/helpers/helpers'

export default {
  props: {
    isreLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed:{
    ...mapState('auth',['user']),
    ...mapState("shop", ["cart", "paymentFormat", "canalventa"]),
  },
  methods:{
    ...mapMutations('shop',['setCanalVenta', 'setEmptyCart', 'deletePaymentMethodClient', 'setPaymentMethodClient', 'clearPaymentList']),
    ...mapMutations('auth',['setChangeChannelUser']),
    ...mapMutations('products',['setSelectedCategory']),
    ...mapActions("products", ["getInitialContent"]),
    ...mapActions("auth", ["fetchPaymentMethods"]),

    async handlerChannelUser(canal){
      if(!this.cart.items.length) await this.changeChannelUser(canal)
      if(this.cart.items.length){
        const confirmAction = await this.confirmAction()
        if( confirmAction ) await this.changeChannelUser(canal)
      }
    },
    async confirmAction(){
      const { isConfirmed } =  await this.$swal.fire({
        title: 'Cambiando canal de venta',
        html: '<strong style="color:red; font-size:18px">¿Desea cambiar de canal de venta? Los productos del carrito serán borrados</strong>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        customClass: { confirmButton: 'btn btn-success', cancelButton: 'btn btn-danger  mr-1' },
        buttonsStyling: false
      })
      return isConfirmed
    },
    async changeChannelUser(canal){
      this.$emit('set-loader-channels', true)
      const channel = toJson(canal)
      this.setChangeChannelUser(channel) //modifico para user
      this.setCanalVenta(channel.canalventa.id)//modifico el id canal venta en shop
      await this.getInitialContent()
      await this.changePaymentMethods()
      this.setSelectedCategory({section: 'services', action: 'get-products-best-seller', text: 'Best Seller', value: 'BestSeller-01' })
      this.setEmptyCart()
      showAlertMessage( `Cambio realizado`, 'BellIcon', 'Se ha cambiado de canal de venta', 'success', 4000, 'bottom-right')
      this.$emit('set-loader-channels', false)
    },
    async changePaymentMethods(){
      await this.fetchPaymentMethods({idUser: this.user.idUser, idChannel: this.canalventa})
      const formaPagoNombre = this.user.fp.find((fp) => fp.id === this.paymentFormat.id)
      this.deletePaymentMethodClient(formaPagoNombre)
      this.setPaymentMethodClient("")
      this.clearPaymentList([])
    },
    activeIf(canal){
      this.isLoading = true
      const channel = toJson(canal)
      let valid = this.user.sectionCanal.name === channel.seccioncanalventa.name
      this.isLoading = false
      return valid
    },
  }
}
</script>


<style>

</style>

